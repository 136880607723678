<template>
  <v-sheet :color="VIDEO_IS_ON ? wsDARKER : 'transparent'" class="fill-height wsRounded overflow-hidden py-6 pr-6 display-bg" >

    <div v-show="started && !isBanned" class="wsRoundedSemi video-container" >
      <video
          ref="video"
          autoplay
          class="mx-auto d-flex video"
      />
      <audio
          v-for="(audio,i) in audioSources" :key="i + audio.value"
          style="opacity:0 !important; position: fixed;pointer-events: none;z-index: -1"
          :id="audio.value"
          :ref="audio.value"
          autoplay
          playsinline
      />
    </div>


    <div v-if="isBanned" class="d-flex align-center fill-height">
      <div>
        <h1 class="wsWHITE mx-auto text-center" style="line-height: 1.3">Звʼязок припитнено зі сторони сервера, це може відбутись через декілька причин</h1>
        <h3 class="wsWHITE mx-auto text-center">Хтось повторной намагався підʼєднатись до трансляції за вашим паролем</h3>
        <h3 class="wsWHITE mx-auto text-center">Адміністратор заборонив доступ до вебінару</h3>
      </div>

    </div>
    <div v-else-if="!started" class="d-flex align-center justify-center pr-8 pt-3 fill-height wsRoundedSemi" style="position:relative;">

      <!-- Preview (awaiting before webinar) -->
      <div class="d-flex align-center justify-center" style="column-gap: 32px; width: 100%; ">
        <div style="width: 540px; height: 540px; object-fit: cover; position: relative">
          <v-img
            style="width: 100%; height: 100%; border-radius: 50%; position: absolute; top: 0; left: -60px"
            :src="WEBINAR.image && WEBINAR.image.trim() !== '' ? WEBINAR.image : require(`@/modules/webinars/assets/no_student_webinar.png`)"
            alt="webinar image"
          />
        </div>
        <div class="wsWHITETEXT" >
          <h2 style="font-size: 22px">{{$t('Webinar')}} «{{WEBINAR.name}}» {{$t('webinar.student.start_in')}}:</h2>


          <ws-timer
              v-if="WEBINAR.time_now && (($time(WEBINAR.date_start).phpTimestamp() - WEBINAR.time_now) > 0)"
              #default="{time}"
              start-on-mount
              :seconds="$time(WEBINAR.date_start).phpTimestamp() - WEBINAR.time_now"
          >
            <h1 style="font-size: 112px; line-height: 150%">
              {{ time }}
            </h1>

          </ws-timer>

          <h5>{{$t('webinar.student.wait_start_label')}}</h5>
        </div>
      </div>
    </div>

    <ws-dialog
        v-model="$store.state.webinars.displayStudentStreamRequestDialog"
        :title="$t('webinar.stream_request.title')"
        :save-text="$t('Send')"
        @save="sendStudentStreamRequest"
    >
      <h4 class="wsDARKER font-weight-regular">
        {{ $t('webinar.stream_request.text') }}
      </h4>
    </ws-dialog>

    <ws-webinar-preview-dialog
        v-if="displayPrepareStreamDialog"
        v-model="displayPrepareStreamDialog"
        @start="handleStudentStartStreaming"
        @exit-webinar="handleStreamExit"
    />

  </v-sheet>
</template>

<script>
import webinars from "@modules/webinars/mixins/webinars";
import WsJanusClient from "@modules/webinars/plugins/wsJanusClient";
import {mapActions} from "vuex";
import WsWebinarPreviewDialog from "@modules/webinars/components/Webinar/admin/room/UI/wsWebinarPreviewDialog.vue";

export default {
  name: "webinarVideoStudent",
  components: {WsWebinarPreviewDialog},
  mixins: [webinars],
  data() {
    return {
      isBanned : false,
      countdownMessage: '',
      started : false,
      displayPrepareStreamDialog : false,

      studentVideoSource : null,
      studentAudioSource : null,

      audioSources : []
    }
  },
  computed: {
    loading() {
      return !this.WEBINAR.uuid
    },
    dateStart() {
      return this.WEBINAR.date_start
    },
  },
  watch : {
    STREAM_EVENTS_LENGTH(value,old) {
      if (value > old) {
        this.handleStreamEvents()
      }
    },
  },
  methods: {
    ...mapActions('stream',
        ['SEND_STREAM_SOCKET_DATA']
    ),

    handleStreamExit() {
      this.$store.state.webinars.studentApprovedToStream = false
      this.displayPrepareStreamDialog = false
    },
    handleStudentStartStreaming(sources) {
      this.studentVideoSource = sources.video
      this.studentAudioSource = sources.audio
      console.log('student streaming sources' , sources)
      this.janus.startClientStream(sources.video, sources.audio)

    },
    handleStreamEvents() {
      let readEvents = []
      for (let event of this.STREAM_EVENTS) {
        if (event.type === 'student_stream_request_reply' && event.webinar === this.WEBINAR.uuid) {
          readEvents.push(event.id)

          this.$store.state.webinars.studentWaitingApproveToStream = false
          this.$store.state.webinars.studentApprovedToStream = event.permission
          this.displayPrepareStreamDialog = event.permission

          if (!event.permission) {
            this.notify(this.$t('webinar.stream_request.request_denied') , 'warning')
          }

        }
      }
      this.$store.state.stream.streamEvents = this.$store.state.stream.streamEvents.filter(el => !readEvents.includes(el.id))
    },

    async sendStudentStreamRequest() {

      let data = {
        type : 'student_stream_request',
        user_id : this.$store.state.auth.userID,
        webinar : this.WEBINAR.uuid,
        session_id : this.janus.getSessionId(),
        handle_id : this.janus.getHandleId(),
      }
      let result = await this.SEND_STREAM_SOCKET_DATA(data)
      if (!result) {
        return this.ERROR()
      }
      this.$store.state.webinars.studentWaitingApproveToStream = true
      this.$store.state.webinars.displayStudentStreamRequestDialog = false

      this.notify(this.$t('webinar.stream_request.request_sent') , 'success')

    },
    stopCallback(type , error) {
      this.isBanned = true
      console.log(type, error)
    },
    async connectToStream() {
      let result = await this.$store.dispatch('stream/GET_JANUS_START_TOKEN' , this.WEBINAR.uuid)
      if (!result) {
        this.ERROR(this.$store.state.ajax.error)
      }

      const videoDom = this.$refs.video

      this.janus = new WsJanusClient(result.server)

      this.janus.joinStream({
        token : result.token,
        roomId : this.WEBINAR.uuid,
        userId : this.$store.state.auth.userID,

        videoCallback : (id, stream, on) => {
          console.log("videoCallback", on, id, stream)
          videoDom.srcObject = stream
          this.started = true
        },
        stopCallback : this.stopCallback,
        audioCallback : this.handleAudioCallback
      })

    },

    handleAudioCallback(audioStream, id, on) {
      if (on && !this.audioSources.map(el => el.value).includes(id)) {
        this.audioSources.push({
          value : id,
          stream: audioStream,
          active : false
        })
        setTimeout( this.refreshAudioDoms,400)
      } else if (!on) {
        console.log('handleAudioCallback: Want to turn off')
        this.audioSources = this.filter(el => el.value === id)
      }


    },

    refreshAudioDoms() {
      console.log('handleAudioCallback: Want to refresh audio doms',this.audioSources)

      for (let index in this.audioSources) {
        console.log(`handleAudioCallback:refresingshAudioDoms + ${index}` , this.audioSources[index])
        if (!this.audioSources[index].active ) {
          let audioDom = this.$refs[this.audioSources[index].value]
          if (audioDom && audioDom[0]) {
            audioDom = audioDom[0]
          }
          console.log(`source ${index}: ` + this.$refs[this.audioSources[index].value])
          this.audioSources[index].dom = audioDom
          this.audioSources[index].dom.srcObject = this.audioSources[index].stream
        }
      }
    },

  },

  mounted() {
    this.connectToStream()
  },
  beforeDestroy() {
    this.FLUSH_VARIABLES()
  }
}
</script>

<style scoped>
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.skeleton-controls {
  background: linear-gradient(90deg, rgba(154, 178, 196, 0.52) 0.5%, rgba(154, 178, 196, 0.32) 30.5%, rgba(154, 178, 196, 0.00) 100%);
}
.display-bg {
  position: relative;
  background-image: url('~@/modules/webinars/assets/display_student_bg.png');
  background-size: cover;
}
</style>
