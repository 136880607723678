<template>
<v-sheet :color="wsDARKER" class="fill-height wsRoundedSemi overflow-hidden pa-6"  style="position:relative">

  <div class="wsRoundedSemi video-container" >

    <video
        id="canvasVideo"
        ref="canvasVideo"
        autoplay
        class="mx-auto d-flex video"
    />
    <!-- Main Stream Element-->
    <canvas
        class="mt-16"
        ref="canvas"
        width="640"
        height="480"
        style="margin: auto; padding: 0; background-color: yellowgreen;display:none"
    />

    <video
           v-for="(video,i) in activeVideoSources" :key="video.value + i"
           :id="video.value"
           :ref="video.value"
           autoplay
           class="mx-auto d-flex video"
           style="opacity:0 !important; position: fixed;pointer-events: none;z-index: -1"
    />
    <audio
        v-for="(audio,i) in activeAudioSourcesFiltered" :key="i + audio.value"
        style="opacity:0 !important; position: fixed;pointer-events: none;z-index: -1"
        :id="audio.value"
        :ref="audio.value"
        autoplay
        playsinline
    />

  </div>

</v-sheet>
</template>

<script>
import webinars from "@modules/webinars/mixins/webinars";
import WsJanusStreamer from "@modules/webinars/plugins/wsJanusStreamer";

export default {
  name: "webinarVideoControls",
  mixins: [webinars],
  data() {
    return {
      layout : 'Style 1',
      stream : null,
      videos : [],
      janus : null
    }
  },
  computed : {
    activeAudioSourcesFiltered() {
      return this.activeAudioSources.filter(el => !el.isAdmin);
    },
    activeAudioSources() {
      return this.AUDIO_SOURCES.filter(el => !!el.active )
    },
    activeVideoSources() {
      return this.VIDEO_SOURCES.filter(el => !!el.active)
    }

  },
  watch : {

    STREAM_EVENTS_LENGTH(value,old) {
      if (value > old) {
        this.handleStreamEvents()
      }
    },

    CANVAS_CASCADE() {
      if (!this.janus) {
        return
      }
      this.janus.setCanvasCascadeType(this.CANVAS_CASCADE)
    },

    START_STREAM_TRIGGER() {
      this.startJanus()
    },

    VIDEO_SOURCES : {
      handler() {
        setTimeout(()=> {
          if (!this.janus) {
            return
          }
          this.janus.setSources(this.getActiveVideoSources())
        },500)
      },
      deep : true
    },
    AUDIO_SOURCES : {
      handler() {
        setTimeout(()=> {
          this.getActiveAudioSources()
        },500)
      },
      deep : true
    },
  },
  methods : {

    // Stream Callback Handling

    handleStreamCallback(data) {

      let {display , isAudio } = data

      let displayData = {}
      try {
        displayData = JSON.parse(display);
      } catch (error) {
        displayData = {};
      }

      let student = {}
      if (displayData.user_id) {
        student = this.PARTICIPANTS.find(el => el.user === displayData.user_id)
      }

      if (isAudio) {
        this.handleAudioStream(data, student)
      } else {
        this.handleVideoStream(data, student)
      }
    },

    handleVideoStream(data, student) {
      let {stream, id, on } = data
      console.log('===> addVideoStreamCallback' , data , student)

      if (on && !this.VIDEO_SOURCES.map(el => el.value).includes(id)) {
        this.$store.state.stream.videoSources.push({
          value : id,
          name : student ? student.name : 'Student',
          stream : stream,
          isLocal : true,
          active : true  ,
          main : false,
        })
      } else if (!on) {
        this.$store.state.stream.videoSources = this.$store.state.stream.videoSources.filter(el => el.value !== id)
      }

    },

    handleAudioStream(data, student) {
      let {stream, id, on } = data
      console.log('===> addAudioStreamCallback' , data , student)

      if (on && !this.AUDIO_SOURCES.map(el => el.value).includes(id)) {

        this.$store.state.stream.audioSources.push({
          value : id,
          name : student ? student.name : 'Student',
          stream : stream,
          isLocal : true,
          active : true  ,
          main : false,
        })

      } else if (!on) {
        this.$store.state.stream.audioSources = this.$store.state.stream.audioSources.filter(el => el.value !== id)
      }

    },

    // Stream Events

    handleStreamEvents() {
      let readEvents = []
      for (let event of this.STREAM_EVENTS) {
        if (event.type === 'student_stream_request' && event.webinar === this.WEBINAR.uuid) {
          readEvents.push(event.id)

          let index = this.PARTICIPANTS.findIndex(el => el.user === event.user_id)

          if (index === -1) {
            return
          }

          this.$store.state.webinars.participants[index].is_requesting_stream = true
          this.$store.state.webinars.participants = this.COPY(this.$store.state.webinars.participants)

        }
      }
      this.$store.state.stream.streamEvents = this.$store.state.stream.streamEvents.filter(el => !readEvents.includes(el.id))
    },

    joinParticipant(joinData) {


      let data = JSON.parse(joinData.display)

      let index = this.PARTICIPANTS.findIndex(el => el.user === data.user_id)

      if (index === -1) {
        return
      }

      this.$store.state.webinars.participants[index].is_online = true
      this.$store.state.webinars.participants = this.COPY(this.$store.state.webinars.participants)


    },

    // Technical

    getActiveVideoSources() {
      let sources = [...this.activeVideoSources]

      for (let index in sources) {

        let videoDom = this.$refs[sources[index].value]
        if (videoDom ?? videoDom[0]) {
          videoDom = videoDom[0]
        }
        sources[index].dom = videoDom
        sources[index].dom.srcObject = sources[index].stream

      }

      sources = sources.filter(el => !!el.dom)

      return sources
    },

    getActiveAudioSources() {
      console.log('getActiveAudioSources')
      let sources = [...this.activeAudioSourcesFiltered]
      console.log('getActiveAudioSources timeout' , sources)
      for (let index in sources) {


        let audioDom = this.$refs[sources[index].value]
        if (audioDom && audioDom[0]) {
          audioDom = audioDom[0]
        }
        console.log(`source ${index}: ` + this.$refs[sources[index].value])
        sources[index].dom = audioDom
        sources[index].dom.srcObject = sources[index].stream

      }

      sources = sources.filter(el => !!el.dom)

      return sources
    },

    // Main Function

    async startJanus() {

      let result = await this.$store.dispatch('stream/GET_JANUS_START_TOKEN' , this.WEBINAR.uuid)
      if (!result) {
        this.ERROR(this.$store.state.ajax.error)
      }

      const sources = this.getActiveVideoSources()
      const audio = this.AUDIO_SOURCES[0]
      const canvas = this.$refs.canvas

      let janus = new WsJanusStreamer(result.server)
      this.janus = janus
      this.janus.setCanvasCascadeType(this.CANVAS_CASCADE)

      janus.startStream({
        token : result.token,
        roomId : this.WEBINAR.uuid,
        userId : this.$store.state.auth.userID,
        sources : sources,
        audio : audio,
        canvas : canvas,

        streamCallback : this.handleStreamCallback,
        removeCallback : this.removeStreamCallback,
        canvasCallback : (stream) => {
          const canvasVideo = this.$refs.canvasVideo
          canvasVideo.srcObject = stream
        },
        joinCallback : this.joinParticipant,
      })

    },

  },

}
</script>


<style scoped>
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>